<template>
  <Popup ref="popupRef" title="购买秒杀位" width="370px" confirm-text="确认支付" @close="resetForm" @confirm="confirm">
    <div class="label red-text">该位置单价：{{form.price}}元/小时</div>
    <el-input placeholder="请输入时长" type="number" v-model="form.num"></el-input>
    <div class="label">价格合计：</div>
    <el-input disabled :value="form.price * form.num"></el-input>
    <div class="label">支付方式：</div>
    <TaskCoinPay></TaskCoinPay>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import TaskCoinPay from '@/components/TaskCoinPay'
export default {
  components: {
    Popup,
    TaskCoinPay
  },
  data() {
    return {
      form: {
        num: '',
        price: '',
        seatNo: ''
      },
      taskId: ''
    }
  },
  watch: {
    'form.num'(newVal) {
      this.form.num = parseInt(newVal || 0)
    }
  },
  methods: {
    open(info) {
      this.form.price = info.price
      this.form.seatNo = info.seatNo
      this.taskId = info.taskId
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.form = {
        num: '',
        price: '',
        seatNo: ''
      }
    },
    confirm() {
      if(this.form.num <= 0) return this.$tips({message: '时长需大于0', type: 'warning'})
      const formData = {
        num: this.form.num,
        price: this.form.price,
        seatNo: this.form.seatNo,
        taskId: this.taskId
      }
      this.close()
      this.$emit('confirm', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 4px;
  margin-top: 24px;
}
</style>