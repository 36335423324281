<template>
  <div>
    <ContentLayout>
      <template #left>
        <div class="panel">
          <div class="flex between-center mb16">
            <PanelTitle title="秒杀推荐" :title-class="['fs20']"></PanelTitle>
            <RulePopover :rule="secKillRule"></RulePopover>
          </div>
          <div class="flex">
            <img class="img-example" src="@/assets/images/example-seckill.png">
            <div class="ml24 flex-1">
              <div class="flex between-center mb8">
                <PanelTitle class="mb8" title="当前选择的任务" :textClass="['fs14']"></PanelTitle>
                <div v-if="currentTask" class="btn-rechoose" @click="$refs.totalPopupRef.open()">
                  <span>重新选择</span>
                  <img src="@/assets/images/arrow-right-theme.png">
                </div>
              </div>
              <CurrentTask :info="currentTask" @click.native="$refs.totalPopupRef.open()"></CurrentTask>
              <!-- 精选任务 -->
              <div class="seckill">
                <img class="img-title" src="@/assets/images/task-jinxuan.png">
                <template v-if="seckillList && seckillList.length > 0" >
                  <div v-for="(item, index) in seckillList" :key="index">
                    <!-- 已购买秒杀 -->
                    <div v-if="item.taskId" class="seckill-item over seckill-task">
                      <div class="task">
                        <img class="avatar" :src="item.taskLogo || require('@/assets/images/avatar-default.png')">
                        <div>
                          <div>{{item.taskProject}}</div>
                          <div class="task-id">ID: {{item.taskId}}</div>
                        </div>
                      </div>
                      <div class="right">
                        <div class="right-top">您购买的秒杀位</div>
                        <div class="flex items-center">
                          <span>释放倒计时：</span>
                          <CountDown :time="item.status == 1 ? item.endCountDown * 1000 : item.releaseCountDown * 1000">
                            <template v-slot:default="scoped">
                              <div class="timeout">
                                <div>{{scoped.hour}}</div>
                                <span>:</span>
                                <div>{{scoped.minute}}</div>
                                <span>:</span>
                                <div>{{scoped.second}}</div>
                              </div>
                            </template>
                          </CountDown>
                        </div>
                      </div>
                    </div>
                    <!-- 未购买秒杀 -->
                    <div v-else :class="['seckill-item', item.status == 0?'over':'']">
                      <div class="left">
                        <img class="icon-seckill" src="@/assets/images/icon-seckill.png">
                        <span class="seckill-title">{{item.status == 1?'正在秒杀':'释放倒计时'}}</span>
                        <CountDown :time="item.status == 1 ? item.endCountDown * 1000 : item.releaseCountDown * 1000">
                          <template v-slot:default="scoped">
                            <div class="timeout">
                              <div>{{scoped.hour}}</div>
                              <span>:</span>
                              <div>{{scoped.minute}}</div>
                              <span>:</span>
                              <div>{{scoped.second}}</div>
                            </div>
                          </template>
                        </CountDown>
                      </div>
                      <div class="btn-purchase" @click="toPurchase(item)">{{item.status == 1 ? '马上抢':'已结束'}}</div>
                    </div>
                  </div>
                </template>
                
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #right>
        <PromoteSection></PromoteSection>
        <Asset class="mt16"></Asset>
      </template>
    </ContentLayout>

    <!-- 抢购弹窗 -->
    <PurchasePopup ref="purchasePopupRef" @confirm="handleConfirm"></PurchasePopup>
    <!-- 全部任务弹窗 -->
    <TaskListTotalPopup ref="totalPopupRef" :list="taskList" 
    @confirm="chooseTask" @loadmore="getTaskList($event.page)"></TaskListTotalPopup>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import PromoteSection from '@/components/PromoteSection'
import Asset from '@/components/Asset'
import ContentLayout from '@/components/ContentLayout'
import CountDown from '@/components/CountDown'
import CurrentTask from "@/components/CurrentTask";
import PurchasePopup from './components/PurchasePopup.vue'
import TaskListTotalPopup from '@/components/TaskListTotalPopup'
import RulePopover from '@/components/RulePopover'
import { pubTasksApi, seckillSeatInfoApi, seatSecKillApi } from '@/api/task.js'
export default {
  name: 'Seckill',
  components: {
    PanelTitle,
    PromoteSection,
    Asset,
    ContentLayout,
    CountDown,
    TaskListTotalPopup,
    CurrentTask,
    PurchasePopup,
    RulePopover
  },
  data() {
    return {
      taskId: '',
      taskList: [],
      seckillList: [],
      secKillRule: '',
      currentTask: null,
    }
  },
  created() {
    const query = this.$route.query
    this.taskId = query.taskId || ''
    if(query.taskDetail) this.currentTask = JSON.parse(decodeURIComponent(query.taskDetail || ''))
    this.getTaskList()
    this.getSeckillList()
  },
  methods: {
    getTaskList(pageNo = 1) {
      pubTasksApi({
        'startPage': pageNo - 1 < 0 ? 0 : pageNo - 1,
				'type': 0
      }).then(res => {
        const list = res.data.taskListItems || []
        if(pageNo == 1) {
          this.taskList = list
        } else {
          this.taskList = this.taskList.concat(list)
          if(list.length == 0) this.$tips({message: '没有更多数据了', type: 'warning'})
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getSeckillList() {
      seckillSeatInfoApi({'num': 0,}).then(res => {
        console.log('秒杀数据', res)
        let list = []
        if(res.data.threeTasksSection && res.data.threeTasksSection.length > 0) {
          list = list.concat(res.data.threeTasksSection)
        }
        if(res.data.fourTasksSection && res.data.fourTasksSection.length > 0) {
          list = list.concat(res.data.fourTasksSection)
        }
        this.seckillList = list
        this.secKillRule = res.data.secKillRule
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    chooseTask(e) {
      this.taskId = e.detail.taskId
      this.currentTask = e.detail
    },
    toPurchase(info) {
      if(info.status != 1) return
      if(!this.taskId) return this.$tips({message: '请先选择一个任务', type: 'warning'})
      this.$refs.purchasePopupRef.open({price: info.price, seatNo: info.seatNo, taskId: this.taskId})
    },
    handleConfirm(formData) {
      seatSecKillApi(formData).then(res => {
        console.log('秒杀结果',res)
        this.$tips({message: res.msg, type: 'success'})
        this.getSeckillList()
        this.$store.dispatch('GET_USER_INFO') // 更新余额
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-rechoose {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--theme-color);
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
}
.rule {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}
.img-example {
  width: 432px;
}
.seckill {
  border: 1px solid #f5f5f5;
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
  .img-title {
    width: 90px;
    height: 24px;
    margin-bottom: 8px;
  }
  .seckill-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    height: 64px;
    border-radius: 8px;
    border: 1px solid #FF0036;
    overflow: hidden;
    margin-top: 4px;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      .icon-seckill {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
      .seckill-title {
        font-weight: bold;
        margin-right: 8px;
      }
    }
    .btn-purchase {
      background-color: #FF0036;
      color: #fff;
      padding: 0 13px;
      height: 64px;
      line-height: 64px;
      cursor: pointer;
    }
    .btn-over {
      background-color: #B0B3BF;
    }
    .timeout {
      display: flex;
      align-items: center;
      &>div {
        width: 28px;
        height: 28px;
        line-height: 28px;
        border-radius: 4px;
        background-color: #FF0036;
        color: #fff;
        text-align: center;
      }
      &>span {
        color: #FF0036;
        margin: 0 4px;
      }
    }
  }
  .over {
    border: 1px solid #B0B3BF;
    .timeout {
      &>div {
        background-color: #B0B3BF;
      }
      &>span {
        color: #B0B3BF;
      }
    }
    .btn-purchase {
      background-color: #B0B3BF;
    }
  }
  .seckill-task {
    padding: 0 24px;
    .task {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        margin-right: 8px;
      }
      .task-id {
        color: #B0B3BF;
        font-size: 12px;
        margin-top: 6px;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 12px;
      .right-top {
        color: #FF0036;
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
    .timeout {
      font-size: 12px;
      &>div {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 4px;
      }
    }
  }
}
</style>